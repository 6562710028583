
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import TableBasic from "@/components/tables/TableBasic.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import StorageService from "@/core/services/StorageService";
import OfficesTE from "@/components/entities/tables-editable/OfficesTE.vue";
import store from "@/store";
import RolesSelect from "@/components/catalogs-select/RolesSelect.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Avatar from "@/components/avatar/avatar.vue";
import { translate } from "@/core/helpers/functions";
import i18n from "@/core/plugins/i18n";

//import Loading from "@/components/Loading.vue";

interface ProfileDetails {
  id: number;
  avatar: string;
  name: string;
  surname: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

export default defineComponent({
  name: "ProfileSettingsUser",
  components: {
    ErrorMessage,
    Field,
    Form,
    OfficesTE,
    RolesSelect,
    TableBasic,
    Avatar,
    //Loading,
  },
  props: {
    profile: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const i18n = useI18n();
    let header = ref([
      { column: t("iname"), key: "name" },
      { column: t("permissionsIndex"), key: "permissions" },
    ]);
    const search = ref("");
    const store = useStore();
    const route = useRoute();
    const tableButtons = ref([]);
    const btnProfileDetails = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const userData = computed(() => store.getters.userData);
    const officesData = computed(() => store.getters.Offices);
    const dataObject = ref([]);
    const fullData = ref([]);
    const isLoading = ref(true);
    const locale = computed(() => i18n.locale.value);
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const emailFormDisplay = ref(false);
    const selectAllLabel = ref(t("iselectall"));
    const showLoading = ref(true);
    const submitButton = ref<HTMLElement | null>(null);

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required(translate("rname")),
      email: Yup.string()
        .email(translate("vemail"))
        .required(translate("remail")),
    });

    // eslint-disable-next-line no-undef
    const checkPermissions = (user_id) => {
      store.commit("setLoadingStatus", true);
      let endpoint = user_id == user.id ? '/api/auth/me' : `/api/users/${user_id}`;
      ApiService.get(endpoint).then(({ data }) => {
        const permissions_list = user_id == user.id ? data.permissions_list : data.permissions_list;
        dataObject.value = permissions_list;
        fullData.value = permissions_list;
        store.commit("setLoadingStatus", false);
        showLoading.value = false;
      });
    };
    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const profileDetails = ref<ProfileDetails>({
      id: user.id,
      avatar: user.avatar || "media/defaults/avatar.png",
      name: user.name,
      surname: "",
      company: "THE CLOUD GROUP",
      contactPhone: "044 3276 454 935",
      companySite: "keenthemes.com",
      country: "MY",
      language: "msa",
      timezone: "Kuala Lumpur",
      currency: "USD",
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let avatar: any;

    const currentAvatar = computed(() => {
      return user.avatar ? user.avatar : "media/defaults/avatar.png";
    });

    const avatarChanged = ref(false);

    const saveProfileDetails = () => {
      if (btnProfileDetails.value) {
        // Activate indicator
        console.clear();
        btnProfileDetails.value.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();
        formData.append("name", userData.value.name);
        formData.append("born_date", userData.value.born_date);
        formData.append("nif_cif", userData.value.nif_cif);
        formData.append("phone", userData.value.phone);
        formData.append("email", userData.value.email);
        formData.append("avatar", avatar);

        ApiService.postAttach(
          "/api/users/update/" + userData.value.id,
          formData
        ).then(({ data }) => {
          StorageService.saveProfile(data);
          store.commit("setUserData", data);
        });

        setTimeout(() => {
          btnProfileDetails.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const previewAvatar = (event) => {
      avatar = event[0];
      avatarChanged.value = true;
    };

    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton5.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: t('emailChangeSuccess'),
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            emailFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const removeImage = () => {
      avatarChanged.value = true;
      userData.value.avatar = "../../media/defaults/blank.png";
      avatar = "";
    };

    onMounted(() => {
      setTimeout(function () {
        checkPermissions(route.params.user_id
            ? route.params.user_id
            : props.profile?.id);
      }, 2000);
    });

    //offices
    const createOffice = () => {
      store.commit("createOffice", {
        id: 0,
        name: "",
        remarks: "",
      });
    };
    const deleteOffice = (i) => {
      officesData.value.forEach(function (value, index) {
        if (index === i) {
          officesData.value.splice(i, 1);
        }
      });
    };
    //
    const saveOffice = () => {
      submitButton2.value?.setAttribute("data-kt-indicator", "on");
      const officesModel = [] as any;
      officesData.value.forEach(function (value) {
        officesModel.push(value.id);
      });
      const data = {
        office_ids: officesModel,
        lang: locale.value,
      };
      ApiService.post(
        "/api/users/" + userData.value.id + "/offices",
        data
      ).then(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");
        }).catch(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");
        });
    };

    const passData = ref({
      oldPass: "",
      newPass: "",
      newPassConfirm: "",
    });

    const updatePassword = () => {
      submitButton3.value?.setAttribute("data-kt-indicator", "on");
      const data = {
        current_password: passData.value.oldPass,
        new_password: passData.value.newPass,
        confirm_new_password: passData.value.newPassConfirm,
        lang: locale.value,
      };
      ApiService.post("/api/users/password/" + userData.value.id, data).then(() => {
        submitButton3.value?.removeAttribute("data-kt-indicator");
      }).catch(() => {
        submitButton3.value?.removeAttribute("data-kt-indicator");
      });
    };

    const selectedRoles = ref([]);
    const rolesSelector = (value) => {
      selectedRoles.value = value;
    };
    const saveRoles = () => {
      submitButton4.value?.setAttribute("data-kt-indicator", "on");
      if (selectedRoles.value.length > 0) {
        const user_id = route.params.user_id
            ? route.params.user_id
            : user.id;
        const data = {
          roles: selectedRoles.value,
        };
        ApiService.post("/api/users/" + user_id + "/roles", data).then(() => {
          submitButton4.value?.removeAttribute("data-kt-indicator");
        }).catch(() => {
          submitButton4.value?.removeAttribute("data-kt-indicator");
        });
      } else {
        Swal.fire({
          text: t("rrole"),
          icon: "error",
          confirmButtonText: t("tryAgain"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButton4.value?.removeAttribute("data-kt-indicator");
      }
    };

    const allFieldSelected = (key) => {
      let trueCount = 0;
      let totalCount = 0;
      userData.value.permissions_list.forEach(function (val, keyVal) {
        if (key === keyVal) {
          val.permissions.forEach(function (perm, permVal) {
            if (userData.value.permissions_list[keyVal].permissions[permVal].active == true) {
              trueCount += 1;
            }
            totalCount += 1;
          });
        }
      });
      let state = trueCount < totalCount ? true : false
      userData.value.permissions_list.forEach(function (val, keyVal) {
        if (key === keyVal) {
          val.permissions.forEach(function (perm, permVal) {
            userData.value.permissions_list[keyVal].permissions[
                permVal
                ].active = state;
            dataObject.value = userData.value.permissions_list;
          });
        }
      });
      onSearch()
    };

    const selectAllField = () => {
      let trueCount = 0;
      let totalCount = 0;
      userData.value.permissions_list.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          if (userData.value.permissions_list[keyVal].permissions[permVal].active == true) {
            trueCount += 1;
          }
          totalCount += 1;
        });
      });
      let state = trueCount < totalCount ? true : false
      selectAllLabel.value = state ? t("ideselectall") : t("iselectall")
      userData.value.permissions_list.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          userData.value.permissions_list[keyVal].permissions[permVal].active =
              state;
          dataObject.value = userData.value.permissions_list;
        });
      });
    };
    const changeRole = (id, value) => {
      userData.value.permissions_list.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          if (perm.id === id) {
            userData.value.permissions_list[keyVal].permissions[
              permVal
            ].active = value;
            dataObject.value = userData.value.permissions_list;
          }
        });
      });
      onSearch()
    };
    const save_permissions = () => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      const user_id = route.params.user_id
        ? route.params.user_id
        : user.id;
      ApiService.post("/api/users/" + user_id + "/permissions", {
        permissions_list: userData.value.permissions_list,
        lang: locale.value,
      }).then(() => {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }).catch(() => {
        submitButton.value?.removeAttribute("data-kt-indicator");
      });
    };
    const onSearch = () => {
      dataObject.value = userData.value.permissions_list.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };
    const permissions = computed(() => store.getters.permissions);
    const canReadPermissions = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "permissions: index"
          ) !== -1
      );
    });
    const canAssignPermissions = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "users: assign permissions"
          ) !== -1
      );
    });
    const canReadRoles = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "roles: index"
          ) !== -1
      );
    });
    const canEditRoles = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "roles: update"
          ) !== -1
      );
    });
    const canUpdatePass = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "users: update password"
          ) !== -1
      );
    });
    const canUpdateUser = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "users: update"
          ) !== -1
      );
    });
    const canEditOffices = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "offices: update"
          ) !== -1
      );
    });
    watch(
        () => locale.value,
        (first) => {
          header = ref([
            { column: t("iname"), key: "name" },
            { column: t("permissionsIndex"), key: "permissions" },
          ]);
        }
    );
    return {
      submitButton,
      showLoading,
      onSearch,
      search,
      selectAllField,
      allFieldSelected,
      save_permissions,
      changeRole,
      saveRoles,
      dataObject,
      fullData,
      tableButtons,
      rolesSelector,
      selectedRoles,
      route,
      store,
      passData,
      updatePassword,
      avatar,
      saveOffice,
      createOffice,
      deleteOffice,
      btnProfileDetails,
      submitButton2,
      submitButton3,
      submitButton4,
      submitButton5,
      previewAvatar,
      saveProfileDetails,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmail,
      updateEmailButton,
      updateEmail,
      userData,
      officesData,
      header,
      apiPath: process.env.VUE_APP_API_URL,
      avatarChanged,
      currentAvatar,
      canReadPermissions,
      canEditRoles,
      canEditOffices,
      canReadRoles,
      canUpdatePass,
      selectAllLabel,
      canUpdateUser,
      canAssignPermissions,
      user,
    };
  }
});
