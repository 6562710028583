
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "RolesSelect",
  props: {
    role_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      // eslint-disable-next-line @typescript-eslint/no-empty-function,vue/require-valid-default-prop
      default: () => [],
    },
  },
  emits: ["update:role_id", "changeRole"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const roleData = computed(() => store.getters.Roles);
    const code = ref("");
    const inputRole = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isRoleRequired = (value) => {
      if (props.required && !value) {
        return t("rofficeid");
      }
      return true;
    };

    let {
      errorMessage,
      value: element_id,
      meta,
    } = useField("role", isRoleRequired);

    const getRoles = () => {
      ApiService.get("/api/roles").then(({ data }) => {
        data.forEach(function (role) {
          store.commit("setRoles", role.id);
        });
        inputRole.value.list = data;
        inputRole.value.options = data;
      });
    };
    const selectRoles = (query) => {
      if (query !== "") {
        inputRole.value.loading = true;
        setTimeout(() => {
          inputRole.value.loading = false;
          inputRole.value.options = inputRole.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputRole.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/roles`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputRole.value.list = data;
              inputRole.value.options = data;
            });
          }
        }, 200);
      } else {
        inputRole.value.options = [];
      }
    };

    watch(
      () => props.role_id,
      (first) => {
        if (first) {
          element_id.value = first;
          const temp = inputRole.value.options.filter(
            (item: Record<string, "">) => {
              return item.id === first;
            }
          );
          if (!props.multiselect) {
            code.value = temp[0]["name"];
            emit("changeRole", code.value);
          }
        }
      }
    );

    watch(
        () => props.selected,
        (first) => {
          element_id.value = []
          first.forEach((item) => {
            element_id.value.push(item.id);
          });
        }
    );

    onMounted(() => {
      setTimeout(function () {
        getRoles();
      }, 3000);
    });

    return {
      element_id,
      inputRole,
      errorMessage,
      meta,
      getRoles,
      selectRoles,
    };
  },
};
