import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileSettingsUser = _resolveComponent("ProfileSettingsUser")!

  return (_ctx.$route.params.user_id !== 'add')
    ? (_openBlock(), _createBlock(_component_ProfileSettingsUser, {
        key: 0,
        profile: _ctx.profile
      }, null, 8, ["profile"]))
    : _createCommentVNode("", true)
}